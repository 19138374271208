import API_URLS from "./apiURLs";
import axios from 'axios';

const validateShopDomain = async (email, shop, token) => {
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        }
        // Add ngrok header only in development
        if (process.env.NODE_ENV === 'development') {
            headers['ngrok-skip-browser-warning'] = 'true';
        }
        const response = await axios.get(`${API_URLS.SHOPIFY.validateShopDomainURL}`, {
            headers,
            params: {
                email,
                shop
            }
        });
        return response.status === 200 ? true : false;
    } catch (error) {
        //console.log(error);
        return false;
    }
}

export default validateShopDomain;