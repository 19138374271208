import axios from 'axios'; // Import axios
import API_URLS from './helpers/apiURLs';
import { toast } from 'react-toastify';


const shopifyDisconnectionHandler = async (shop, setLoading, updateshopifyInstallFlag) => {
    try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('userEmail');
        // Call disconnection endpoint. 
        const headers = {
            'Authorization': `Bearer ${token}`,
        }
        // Add ngrok header only in development
        if (process.env.NODE_ENV === 'development') {
            headers['ngrok-skip-browser-warning'] = 'true';
        }
        const response = await axios.delete(`${API_URLS.SHOPIFY.uninstallApplicationURL}?email=${encodeURIComponent(email)}&shop=${shop}`, {
            headers
        });
        setLoading(false);
        if (response.status === 200) {
            updateshopifyInstallFlag();
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message)
        }
    } catch (error) {
        setLoading(false);
        //console.log("Error", error);
        return toast.error(error?.response?.data?.message || 'An unexpected error occurred');
    }
};

export default shopifyDisconnectionHandler; // Export the function