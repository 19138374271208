import React from 'react';
import styles from '../styles/FormBackground.module.css';

const FormBackground = ({ headingText, requiredText, children }) => (
    <div className={styles.pricing_plan_box}>
        {/* <div className={styles.pricing_plan_header}>
            <div className={styles.pricing_plan_name}>
                {headingText} <span className={styles.required_text}>{requiredText}</span>
            </div>
        </div> */}
        {/* <div className={styles.heading_form_divider}></div> */}
        <div>{children}</div>
    </div>
);

export default FormBackground;
