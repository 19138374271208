import { useState } from 'react'
import moment from 'moment'
import styles from './styles/TableFilterOptions.module.css';
import { Button } from './common/components/orderTracking.common.components';

const TableFilterOptions = ({ fromDate, setFromDate, toDate, setToDate }) => {
    const todaysDate = moment().format('YYYY-MM-DD')

    return (
        <div className={styles.date}>
            <label>From:</label>
            <input
                value={fromDate}
                onChange={(e) => setFromDate(e.target?.value)}
                max={todaysDate}
                type='date'
            />
            <div style={{ marginRight: '20px' }}></div>
            <label>To:</label>
            <input
                value={toDate}
                onChange={(e) => setToDate(e.target?.value)}
                max={todaysDate}
                type='date' />
            <div style={{ marginRight: '20px' }}></div>
            <Button
                text="Reset"
                className={'button_red'}
                type='submit'
                onClick={() => { setFromDate(""); setToDate(""); }}
            />
        </div >
    )
}


export default TableFilterOptions;