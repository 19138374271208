import TableFilterOptions from './TableFilterOptions';
import styles from './common/styles/Table.module.css';
import Pagination from '../small/Pagination'
import { useState } from 'react'


import moment from 'moment'

import { Button } from './common/components/orderTracking.common.components';




import { useOrders, useOrdersTotal } from '../../swr/index'

import './style.css';
import { Link } from 'react-router-dom';
import FooterNavigation from '../FooterNavigation'


import { PAYMENT_METHOD_TITLE, DELIVERY_STATUS } from '../../common/constants'
import DeliveryStatusFormat from '../UI/DeliveryStatusFormat'

const OrderTable = ({ limit = 15 }) => {
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const { total, isTotalLoading, isTotalError } = useOrdersTotal(fromDate, toDate)
    const { orders, isLoading, isError } = useOrders(currentPage, limit, '', fromDate, toDate)

    if (isLoading) return 'Loading...'
    if (isError) return 'Error...'

    const subtotalSum = orders.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.subtotal : 0), 0)
    const deliverySum = orders.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.delivery_fee : 0), 0)
    return (
        <>
            <TableFilterOptions
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
            />
            <table className={styles.data_table}>
                <thead>
                    <tr>
                        <th>Invoice #</th>
                        <th>Order Details</th>
                        <th>Customer Name</th>
                        <th>Email or Phone #</th>
                        <th>Payment Method</th>
                        <th>Subtotal</th>
                        <th>Delivery Fees</th>
                        <th>Total</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td><Link to={`/order/${order.id}`}>{order.invoice_number}</Link></td>
                            <td>{order.item_ids ? order.item_ids.length : 0} items</td>
                            <td>{order.customer_name}</td>
                            <td>{order.customer_phone_number ? order.customer_phone_number : order.customer_email}</td>
                            <td>{PAYMENT_METHOD_TITLE[order.payment_method]}</td>
                            <td>{Math.abs(order.subtotal).toFixed(3)}<br />{order.currency}</td>
                            <td>{Math.abs(order.delivery_fee).toFixed(3) || 0}<br />{order.currency}</td>
                            <td>{Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(3)}<br />{order.currency}</td>
                            <td align="center"><DeliveryStatusFormat status={order.status} /></td>
                        </tr>
                    ))}
                    {/* <tr key="final-totals">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="has-background-grey-light has-text-weight-bold">Total</td>
                <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum).toFixed(3)} KWD</td>
                <td className="has-background-grey-light has-text-weight-bold">{Math.abs(deliverySum).toFixed(3)} KWD</td>
                <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum + deliverySum).toFixed(3)} KWD</td>
                <td align="center"></td>
              </tr> */}
                </tbody>
            </table>
            <div style={{ marginBottom: '20px' }}></div>
            <Pagination
                currentPage={currentPage}
                setPage={setCurrentPage}
                totalPages={Math.ceil(total / limit)}
            />

        </>
    )
}


export default OrderTable;