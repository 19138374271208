import { useState } from 'react';
import BusinessNavigation from '../FooterNavigation';
import { PageLayout, FormBackground, OrderButton } from './common/components/orderTracking.common.components';
import styles from './styles/OrderTrackingDashboardOverview.module.css';
import OrderTable from './OrderTable';


const OrderTrackingDashboardOverview = () => {

  const [buttonClicked, setButtonClicked] = useState('AllOrders');


  const handleAllOrdersSelection = () => {
    setButtonClicked('AllOrders');
  }

  const handleShopifyOrdersSelection = () => {
    setButtonClicked('ShopifyOrders');
  }

  const handleManualOrdersSelection = () => {
    setButtonClicked('ManualOrders');
  }

  return (
    <PageLayout
      title="Orders Tracking"
      activeTab="dashboard"
      NavigationComponent={BusinessNavigation}
    >
      <>
        <FormBackground>
          <div className={styles.top_button_layer}>
            <OrderButton
              text="All Orders"
              className={buttonClicked === 'AllOrders' ? 'button_blue' : 'button_grey'}
              type='button'
              onClick={handleAllOrdersSelection}
            />
            <OrderButton
              text="Shopify Orders"
              className={buttonClicked === 'ShopifyOrders' ? 'button_blue' : 'button_grey'}
              type='button'
              onClick={handleShopifyOrdersSelection}
            />
            <OrderButton
              text="Manual Orders"
              className={buttonClicked === 'ManualOrders' ? 'button_blue' : 'button_grey'}
              type='button'
              onClick={handleManualOrdersSelection}
            />
          </div>
          <div className={styles.heading_form_divider}></div>
          <>
            <FormBackground>
              <OrderTable />
            </FormBackground>
          </>
        </FormBackground>
      </>


    </PageLayout>
  );
};

export default OrderTrackingDashboardOverview;