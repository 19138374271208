const SHOPIFY_APP_BASE_URL = process.env.REACT_APP_SHOPIFY_APP_BASE_URL;
const SHOPIFY_APP_BASE_API_URL = '/api/v1/shopify';

const SHOPIFY_APP_API_URLS = {
    validateShopDomainURL: `${SHOPIFY_APP_BASE_URL}${SHOPIFY_APP_BASE_API_URL}/install/validateShopDomain`,
    generateInstallURL: `${SHOPIFY_APP_BASE_URL}${SHOPIFY_APP_BASE_API_URL}/install/generateInstallURL`,
    uninstallApplicationURL: `${SHOPIFY_APP_BASE_URL}${SHOPIFY_APP_BASE_API_URL}/uninstall`,

};

// You can combine them if needed
const API_URLS = {
    SHOPIFY: SHOPIFY_APP_API_URLS,
};
module.exports = API_URLS;
