import axios from 'axios';
import API_URLS from './apiURLs';


const generateStoreInstallationUrl = async (email, shop, token) => {
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        }
        // Add ngrok header only in development
        if (process.env.NODE_ENV === 'development') {
            headers['ngrok-skip-browser-warning'] = 'true';
        }
        const response = await axios.get(`${API_URLS.SHOPIFY.generateInstallURL}`, {
            headers,
            params: {
                email,
                shop
            }
        });
        return response.status === 200 ? { url: response.data.url } : { url: null };
    } catch (error) {
        //console.log(error);
        return { url: null }
    }
}

export default generateStoreInstallationUrl;
