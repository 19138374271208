import { toast } from "react-toastify";

const openInstallationWindow = (url, setLoading, refetchUserData) => {
    return new Promise((resolve, reject) => {
        let popup;
        try {
            const width = 1000;
            const height = 600;
            // Calculate the position to center the popup
            const left = (window.outerWidth / 2) - (width / 2);
            const top = (window.outerHeight / 2) - (height / 2);
            //console.log(left , top);
            // Open the URL in a centered popup window
            popup = window.open(url, 'Shopify OAuth', `width=${width},height=${height},top=${top},left=${left}`);
            // Alert on refresh
            const alertOnrefresh = (event) => {
                event.preventDefault();
            }
            window.addEventListener('beforeunload', alertOnrefresh);
            // Wait time to utomatically close the popup window or on user action
            const timeout = 5 * 60 * 1000; // 5 minutes in milliseconds
            const startTime = Date.now(); // Get the current time when the popup opens
            const checkPopupClosed = setInterval(() => {
                if (popup.closed || (Date.now() - startTime >= timeout)) {
                    clearInterval(checkPopupClosed); // Stop checking when closed
                    refetchUserData();
                    popup.close();
                    setLoading(false); // Hide loading spinner
                    resolve();
                }
            }, 500); // Check every second
        }
        catch (error) {
            refetchUserData();
            setLoading(false);
            if (popup) { popup.close(); }
            reject(error);
        }
    });
}

export default openInstallationWindow;