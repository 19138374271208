import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validateShopDomain from './helpers/validateShopDomain';
import generateStoreInstallationUrl from './helpers/generateStoreInstallationUrl';
import openInstallationWindow from './helpers/openInstallationWindow';


const shopifyConnectionHandler = async (shop, setLoading, refetchUserData) => {
    try {
        if (!shop) {
            return toast.error("Shop validation failed. Please enter a valid Shopify domain in the format 'storeName.myshopify.com'.");
        }
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('userEmail');
        // Check if the shop domain is valid .....
        const success = await validateShopDomain(email, shop, token);
        if (!success) {
            return toast.error("Shop validation failed. Please enter a valid Shopify domain in the format 'storeName.myshopify.com'.");
        }
        // Get the Installation URL 
        const { url } = await generateStoreInstallationUrl(email, shop, token);
        if (!url) {
            return toast.error('Connection error: Unable to connect to Shopify. Please contact Onway support for assistance.');
        }
        // Starting the installation process
        setLoading(true);
        openInstallationWindow(url, setLoading, refetchUserData)
            .catch(error => {
                toast.error('Promise: Connection error: Unable to connect to Shopify. Please contact Onway support for assistance.');
            });
        return;
    } catch (error) {
        //console.log(error);
        return toast.error('Connection error: Unable to connect to Shopify. Please contact Onway support for assistance.');
    }
};

export default shopifyConnectionHandler;