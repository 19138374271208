import React from 'react';
import styles from '../styles/OrderButton.module.css';

const OrderButton = ({ text, onClick, className, icon: Icon, ...props }) => {
  return (
    <button
    className={`${styles.button_base} ${styles[className]}`}
      onClick={onClick}
      {...props}
    >
      {Icon && <Icon className={styles.icon_spacing} />}
      {text}
    </button>
  );
};

export default OrderButton;
