import { useOrdersDriversHistory, useOrdersDriverTotal } from '../../swr/index'
import { useState } from 'react'
import { DELIVERY_STATUS_TITLE, DELIVERY_STATUS_COLOR, DELIVERY_STATUS, PAYMENT_METHOD_TITLE, API_URL, POST_FETCH_OPTIONS } from '../../common/constants'
import DriverNavigation from './Navigation'
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '../../components/small/Pagination'
import BusinessName from '../../components/small/BusinessName'
import BusinessPhoneNumber from '../../components/small/BusinessPhoneNumber'
import moment from 'moment'
import { useSWRConfig } from 'swr'

function Table({ page, limit, from, to }) {

	const { orders, isLoading, isError } = useOrdersDriversHistory(page, limit, from, to)
	const { mutate } = useSWRConfig();

	const navigate = useNavigate()

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const subtotalSum = orders.reduce((sum, order) => sum + (order.status !== DELIVERY_STATUS.CANCELED ? parseFloat(order.subtotal) : 0), 0);
	const deliverySum = orders.reduce((sum, order) => sum + (order.status !== DELIVERY_STATUS.CANCELED ? parseFloat(order.delivery_fee) : 0), 0);
	
	const updateStatus = async (order, newStatus) => {
		if (!newStatus) {
 			return
		}

		const userId = localStorage.getItem('userId')
		if (! userId) { return }

		try {
		  const result = await fetch(`${API_URL.ORDERS}/${order.id}`, POST_FETCH_OPTIONS({
		    ...order,
		    driver_id: userId,
		    status: newStatus,
		  }))
		  const data = await result.json()
		  mutate([API_URL.ORDERS_DRIVER_HISTORY, '?page=1&limit=50'])
		} catch (err) {
		}
	}

	return(
		<div className="table-container">
		  <table className="table mx-auto is-bordered is-fullwidth">
		    <thead>
		      <tr>
		      	<th>Status</th>
		        <th>Invoice #</th>
		        <th>Order Details</th>
		        <th>Customer Payment Method</th>
		        <th>Business Name / Phone Number</th>
		        <th>Subtotal</th>
		        <th>Delivery Fees</th>
		        <th>Total</th>
		      </tr>
		    </thead>
		    <tbody>
		      {orders.map(order => (
		        <tr key={order.id}>
		        	<td align="center" style={{ color: DELIVERY_STATUS_COLOR[order.status] }}>
		        		<p>{DELIVERY_STATUS_TITLE[order.status]}</p>
		        		{(order.status === DELIVERY_STATUS.ACCEPTED || order.status === DELIVERY_STATUS.ON_THE_WAY) && 
		        			<select onChange={(e) => updateStatus(order, e.target?.value)}>
		        				<option value="">Update delivery status to</option>
		        				<option value={DELIVERY_STATUS.ON_THE_WAY}>{DELIVERY_STATUS_TITLE[DELIVERY_STATUS.ON_THE_WAY]}</option>
		        				<option value={DELIVERY_STATUS.DELIVERED}>{DELIVERY_STATUS_TITLE[DELIVERY_STATUS.DELIVERED]}</option>
		        			</select>
		        			//<button onClick={() => navigate(`/driver/leaderboard/${order.id}`)}>Resume</button>
		        		}
		        	</td>
		          <td><Link to={`/order/${order.id}`}>{order.invoice_number}</Link></td>
		          <td>{order.item_ids ? order.item_ids.length : 0} items</td>
		          <td>{PAYMENT_METHOD_TITLE[order.payment_method]}</td>
		          <td><BusinessName userId={order.user_id} /> / <BusinessPhoneNumber userId={order.user_id} /></td>
		          <td>{Math.abs(order.subtotal).toFixed(3)} KWD</td>
		          <td>{Math.abs(order.delivery_fee).toFixed(3) || 0} KWD</td>
		          <td>{Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(3)} KD</td>
		        </tr>
		      ))}
			      <tr key="final-totals">
			        <td></td>
			        <td></td>
			        <td></td>
			        <td></td>
			        <td className="has-background-grey-light has-text-weight-bold">Total</td>
			        <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum).toFixed(3)} KWD</td>
			        <td className="has-background-grey-light has-text-weight-bold">{Math.abs(deliverySum).toFixed(3)} KWD</td>
			        <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum + deliverySum).toFixed(3)} KWD</td>
			      </tr>
		    </tbody>
		  </table>
		</div>
	)
}

export default function OrderHistory() {
	
	const limit = 50;
	const { total, isLoading, isError } = useOrdersDriverTotal()

	const [currentPage, setCurrentPage] = useState(1)
	
	const [selectedFromDate, setSelectedFromDate] = useState('')
	const [selectedToDate, setSelectedToDate] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const today = moment().format('YYYY-MM-DD')

	const clearSearch = () => {
		setSelectedFromDate('')
		setSelectedToDate('')
	}

	return (
		<div className="container">
			<h1 className="is-size-1 has-text-centered my-5">Order History</h1>
			<DriverNavigation activeTab="driver-order-history" />
			<div className="my-2">
				<span>From: <input value={selectedFromDate} onChange={(e) => setSelectedFromDate(e.target?.value)} className="mr-2"  max={today} type='date' /></span>
				{selectedFromDate && <span>To: <input value={selectedToDate} onChange={(e) => setSelectedToDate(e.target?.value)} className="mr-2" type='date' min={selectedFromDate} max={today} /></span>}
				{(selectedFromDate && selectedToDate) && <button onClick={() => clearSearch()}>Reset</button>}
			</div>
			<Table page={currentPage} limit={limit} from={selectedFromDate} to={selectedToDate} />
			{(!selectedFromDate && !selectedToDate) && <Pagination currentPage={currentPage} setPage={setCurrentPage} totalPages={Math.ceil(total / limit)} />}
		</div>
	)
}
